// Desktop Styles
.hk-desktop-inventory-list-search {
  .hk-search-bar {
    padding: 16px 0;
    width: 358px;
    color: var(--ion-color-grey);
  }
}

.hk-desktop-inventory-list {
  max-width: 1440px;
  margin: auto;
  margin-top: 32px;
  padding: 0 32px;
  .hk-list-card.no-image .hk-list-card-thumbnail img {
    transform: scale(0.6);
    color: var(--ion-color-medium);
    opacity: 0.2;
  }
}

// Mobile Styles
.hk-inventory-list {
  .hk-list-card.no-image .hk-list-card-thumbnail img {
    transform: scale(0.6);
    color: var(--ion-color-medium);
    opacity: 0.2;
  }
}
