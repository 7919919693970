// Desktop Navigation Styles
.hk-desktop-nav-header-container {
  background-color: var(--ion-color-grey);
  width: 100%;
}
.hk-desktop-nav-header {
  display: flex;
  flex-direction: column;
  height: 170px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 32px;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    &-logo {
      width: 200px !important;
    }
    &-nav {
      cursor: pointer;
      ion-icon {
        color: white;
        font-size: 16px;
        margin: 0 10px;
      }
      ion-item {
        --background: none;
        --border-style: none;
        --color: white;
        font-size: 16px;
        font-weight: bold;
        &.selected {
          color: var(--ion-color-primary);
          ion-icon {
            color: var(--ion-color-primary);
          }
        }
        &::part(native) {
          padding: 0;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &-image {
      position: relative;
      bottom: -8px;
      width: 192px !important;
      height: 82px;
    }
  }
}
