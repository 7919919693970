// Desktop View Styles
.login-view-desktop {
  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .hk-illustration img {
    width: 240px;
    margin-top: 64px;
    margin-bottom: 32px;
  }
  h3.login-title {
    font-size: 24px;
  }
  h6.login-subheader {
    color: var(--ion-color-medium);
    font-weight: 600;
    padding: 0;
  }
  .login-content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 420px;
    width: 580px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .login-content {
    width: 326px;
  }
  .hk-auth-button {
    width: 100%;
  }
  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    width: 100%;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }
  .login-view-password-form {
    margin: 0;
    padding: 0;
  }
  .login-view-footer {
    width: 580px;
  }
}

// Mobile View Styles
.login-view {
  --background: #fff !important;
  h3 {
    font-weight: bold;
  }

  h6.login-subheader {
    color: var(--ion-color-medium);
    font-weight: 600;
    padding: 0 15% 0;
  }

  .hk-illustration img {
    max-width: 70%;
    margin-top: 64px;
  }

  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    width: 100%;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }

  &-provider-list {
    display: flex;
    flex-direction: column;
    &-separator {
      text-align: center;
      margin: 8px 0;
      span {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: var(--ion-color-medium) !important;
        background-color: #fff;
        padding: 10px;
      }
      hr {
        border-width: 1px 0 0 0;
        border-color: var(--ion-color-medium);
        border-style: solid;
        margin: -10px 4px 0 4px;
      }
    }
  }

  &-password-form {
    .hk-form {
      width: 100%;
      margin: auto;
      .login-field {
        --background: transparent;
        border-bottom: 1px solid var(--ion-color-medium);
      }
      &-actions {
        margin-top: 8px;
      }
      &-row {
        margin-left: 0;
        margin-right: 0;
        input {
          width: 100%;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: var(--ion-color-medium) !important;
          padding: 16px 0;
          border-radius: 0;
          flex-grow: 1;
          background-color: #fff;
          border: 1px solid #fff;
          border-bottom: 1px solid var(--ion-color-medium);
          &:focus {
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            color: var(--ion-color-medium) !important;
            background-color: #fff !important;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
        &-actions {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &-footer {
    text-align: center;
    &-help {
      padding: 14px;
      font-size: 17px;
      color: var(--ion-color-step-200);
      background-color: var(--ion-color-step-950);
    }
  }
}
