// Desktop Styles
.hk-desktop-document-list {
  max-width: 1440px;
  margin: auto;
  margin-top: 32px;
  padding: 0 32px;
  &.photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &-img {
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30%;
    height: 0;
    padding-top: 45%;
    margin: 8px;
  }
}

// Mobile Styles
.hk-document-list {
  &.photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &-img {
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 45%;
    height: 0;
    padding-top: 45%;
    margin: 8px;
  }
}
