.hk-illustration {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 0 auto;
    border: 0;
  }
}
