// Desktop Styles
.hk-desktop-list-card {
  --background: var(--ion-color-grey);
  --border-radius: 10px;
  --min-height: 100px;
  margin-bottom: 4px;
  &:hover {
    cursor: pointer;
    --background: var(--hk-color-discovery);
  }

  &-thumbnail {
    height: 48px;
    width: 48px;
    background: #fff;
    border-radius: 8px;
    margin-left: 8px;
    img {
      border-radius: 8px;
    }
  }

  &-label {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-title {
    width: 33%;
    h2 {
      color: #fff;
      text-align: left;
      font-size: 18px !important;
      font-weight: bold !important;
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    width: 33%;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    p {
      font-weight: normal;
      margin: 4px 0;
      color: var(--hk-color-vulcan);
    }
  }
}

// Mobile Styles
.hk-list-card {
  --background: var(--ion-color-grey);
  --border-radius: 10px;
  --min-height: 106px;
  margin-bottom: 8px;

  &-title {
    color: #fff;
    text-align: left;
    margin-bottom: 4px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  &-description {
    text-align: left;
    font-size: 14px;
    padding-top: 4px !important;
  }

  &-thumbnail {
    height: 74px;
    width: 74px;
    background: #fff;
    border-radius: 8px;
    img {
      border-radius: 8px;
    }
  }
}
