// Desktop View Styles
.hk-desktop-dashboard-toggle {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  & > div {
    padding: 8px 0;
    &:not(:first-child) {
      margin-left: 32px;
    }
  }
  .selected {
    color: var(--ion-color-primary);
    border-bottom: 4px solid var(--ion-color-primary);
  }
}
.hk-desktop-dashboard-appointments {
  max-width: 1440px;
  margin: auto;
  padding: 0 32px;
  padding-top: 32px;
}

// Mobile View Styles
.hk-dashboard {
  background-color: var(--ion-color-grey);
  overflow: visible;
  &-image {
    text-align: center;
    position: relative;
    bottom: -32px;
    .hk-illustration img {
      margin-top: 0;
    }
  }
  &-toggle {
    z-index: 10;
    position: relative;
    bottom: -18px;
    width: 244px;
    height: 40px;
    background-color: var(--ion-background-color);
    border-radius: 20px;
    margin: auto;
    display: flex;
    align-items: center;
    ion-chip {
      width: 118px;
      height: 32px;
      justify-content: center;
      font-weight: bold;
      color: var(--ion-color-grey);
      background-color: var(--ion-background-color);
      &.selected {
        color: #fff;
        background-color: var(--ion-color-grey);
      }
    }
  }
}
