ion-searchbar.hk-search-bar {
  padding: 12px 16px;
  input {
    font-size: 14px !important;
    --background: #fff;
    --border-radius: 4px;
  }
  .searchbar-search-icon {
    color: #000;
    width: 16px;
  }
}
