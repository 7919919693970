// Desktop Styles
.hk-desktop-documents-group-search {
  .hk-search-bar {
    padding: 16px 0;
    width: 358px;
    color: var(--ion-color-grey);
  }
}

.hk-desktop-documents-group {
  &-list {
    max-width: 1440px;
    margin: auto;
    margin-top: 32px;
    padding: 0 32px;

    .hk-desktop-documents-group-list-item-start-icon {
      min-width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 4px;
      background-color: var(--ion-color-primary);
      margin-right: 24px;
    }

    &-item-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        width: 50%;
        h2 {
          text-align: left;
          margin: 0px !important;
          font-size: 18px !important;
          font-weight: bold !important;
        }
      }
      &-description {
        width: 50%;
        text-align: left;
        font-size: 16px;
      }
      &-end-icon {
        font-size: 24px;
      }
    }
  }
}
