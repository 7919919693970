// Desktop Styles
.hk-desktop-contacts-search {
  .hk-search-bar {
    padding: 16px 0;
    width: 358px;
    color: var(--ion-color-grey);
  }
}

.hk-desktop-contacts {
  &-list {
    max-width: 1440px;
    margin: auto;
    margin-top: 32px;
    padding: 0 32px;
  }
}

// Mobile Styles
.hk-contacts {
  .hk-list-item-end-icon {
    height: 24px;
    width: 24px;
  }
}
