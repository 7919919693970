// Desktop Styles
.hk-desktop-person-card {
  display: flex;
  align-items: center;
  background-color: var(--ion-color-grey);
  border-radius: 10px;
  padding: 24px;

  &-thumbnail {
    min-width: 48px;
    min-height: 48px;
    margin-right: 24px;
    img {
      border-radius: 6px;
    }
  }
  &-details {
    display: flex;
    width: 100%;
    font-size: 16px;
    .hk-service-person-title {
      margin-bottom: 4px;
    }
    &-name {
      width: 30%;
      display: flex;
      flex-direction: column;
    }
    &-date {
      width: 30%;
      display: flex;
      flex-direction: column;
    }
    &-time {
      width: 30%;
      display: flex;
      flex-direction: column;
    }
    &-status {
      width: 20%;
      display: flex;
      flex-direction: column;
      .hk-status-pill {
        font-weight: bold;
        font-size: 10px;
        height: 20px;
        border-radius: 3px;
        padding: 3px 8px;
        width: 74px;
      }
      .complete {
        width: 64px;
        color: var(--ion-color-dark);
        background-color: var(--hk-color-enterprise);
      }
      .incomplete {
        width: 74px;
        color: var(--ion-color-white);
        background-color: var(--hk-color-klingon);
      }
    }
  }

  &-footer {
    .hk-desktop-schedule-button {
      --background: var(--ion-color-primary);
      --border-radius: 20px !important;
      --box-shadow: none;
      font-weight: bold;
      color: #fff;
      width: 96px;
      height: 40px;
      text-transform: none;
      letter-spacing: inherit;
    }
  }
}

// Mobile Styles
.hk-person-card {
  display: block;
  background-color: var(--ion-color-grey);
  border-radius: 10px;
  padding: 16px;

  &-header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &-name {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ion-thumbnail {
      width: 80px;
      height: 80px;
      margin-right: 16px;
      img {
        border-radius: 6px;
      }
    }
    &-details {
      flex: 1 1 auto;
      font-size: 14px;
      div {
        &:first-child {
          font-size: 16px;
        }
        margin-bottom: 6px;
        display: flex;
        align-items: center;
      }
      ion-icon {
        margin-right: 8px;
      }
    }
    p {
      margin-top: 0;
      font-size: 14px;
    }
  }

  &-rating {
    margin-bottom: 16px;
    ion-icon {
      font-size: 22px;
      margin-right: 8px;
    }
  }

  &-footer {
    padding-top: 16px;
    border-top: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-completed {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      ion-icon {
        margin-right: 4px;
      }
    }

    &-pending {
      font-weight: bold;
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 4px;
      }
    }
  }
}
