.hk-schedule {
  &-header {
    padding-top: 80px !important;
    background-color: var(--ion-color-grey);

    &-text h1 {
      font-size: 40px !important;
      &.name h1 {
        margin-top: 0;
        font-weight: bold !important;
      }
    }

    p {
      font-size: 14px;
      padding: 0;
    }
  }

  &-body {
    background-color: var(--ion-background-color);
    ion-button {
      font-weight: bold;
    }
    .hk-illustration {
      margin-top: 0;
    }
    .hk-item-title {
      font-weight: normal !important;
      font-size: 14px !important;
    }
    .hk-item-description {
      font-weight: bold !important;
      font-size: 16px !important;
      text-transform: uppercase;
    }
  }

  .hk-card-image {
    margin-right: 8px;
  }

  h1.hk-card-text {
    font-size: 18px;
    font-weight: bold !important;
    margin-bottom: 8px;
  }

  h3.hk-card-text {
    font-size: 14px;
    font-weight: bold !important;
  }
}

/*.schedule-body .hk-illustration img {*/
/*  height: 120px;*/
/*}*/
