// Desktop Styles
.hk-desktop-account {
  --background: var(--ion-color-dark);
  max-width: 1440px;
  margin: auto;
  margin-top: 32px;
  padding: 0 32px;

  &-header {
    display: flex;
    background: var(--ion-color-dark);
    border-bottom: 1px solid var(--ion-color-grey);
    padding: 32px;
    border-radius: 10px 10px 0 0;
    ion-text {
      color: #fff;
      display: block;
      font-size: 40px;
      &:nth-child(2) {
        margin-left: 10px;
        font-weight: bold;
      }
    }
  }

  ion-list {
    background: var(--ion-color-dark);
    padding: 0;
  }

  &-item {
    &::part(native) {
      padding: 32px;
      border-bottom: 1px solid var(--ion-color-grey);
    }
  }
  .editable {
    &:hover {
      cursor: pointer;
    }
  }
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &-details {
    display: flex;
    flex-direction: column;
  }
  &-label {
    margin-bottom: 4px;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  &-value {
    margin: 0 !important;
    font-size: 14px !important;
  }
  &-edit-icon {
    font-size: 24px;
  }
  &-footer {
    display: flex;
    justify-content: center;
    background: var(--ion-color-dark);
    padding: 32px;
    border-radius: 0 0 10px 10px;
    ion-button {
      font-weight: bold;
      text-transform: none;
      width: 310px;
      height: 50px;
    }
  }
}

// Mobile Styles
.hk-account {
  --background: var(--ion-color-dark);
  .ion-content {
    --background: var(--ion-color-dark);
  }

  &-header {
    border-bottom: 1px solid var(--ion-color-grey);
    padding: 32px;
    ion-text {
      color: #fff;
      display: block;
      font-size: 40px;
      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }

  ion-list {
    background: var(--ion-color-dark);
  }

  &-label {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 18px !important;
    font-weight: bold !important;
  }

  &-value {
    margin: 0 !important;
    font-size: 14px !important;

    ion-text {
      display: inline-block;
      height: 22px;
    }
  }

  &-edit-icon {
    margin-top: 24px;
  }

  ion-button {
    font-weight: bold;
  }
}
