.reset-view {
  --background: #fff !important;

  &-header {
    margin-top: 50%;
    margin-bottom: 32px;
  }

  h3 {
    font-weight: bold;
  }

  h6.reset-view-subheader {
    color: var(--ion-color-medium);
    font-weight: 600;
    padding: 0 15% 0;
  }

  .hk-illustration img {
    max-width: 70%;
    margin-top: 64px;
  }

  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    width: 100%;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }

  &-email-form {
    .hk-form {
      width: 100%;
      margin: auto;
      .login-field {
        --background: transparent;
        border-bottom: 1px solid var(--ion-color-medium);
      }
      &-actions {
        margin-top: 8px;
      }
      &-row {
        margin-left: 0;
        margin-right: 0;
        input {
          width: 100%;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: var(--ion-color-medium) !important;
          padding: 16px 0;
          border-radius: 0;
          flex-grow: 1;
          background-color: #fff;
          border: 1px solid #fff;
          border-bottom: 1px solid var(--ion-color-medium);
          &:focus {
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            color: var(--ion-color-medium) !important;
            background-color: #fff !important;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
        &-actions {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &-footer {
    text-align: center;
    &-help {
      padding: 14px;
      font-size: 17px;
      color: var(--ion-color-step-200);
      background-color: var(--ion-color-step-950);
    }
  }
}
