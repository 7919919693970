// Desktop Styles
.hk-desktop-appointment-proposals {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
  margin-top: 48px;
  .hk-desktop-appointment-proposals-calendar {
    width: 65%;
    min-width: 804px;
    margin-right: 8px;
    &__title {
      display: flex;
      align-items: center;
      background: var(--ion-color-white);
      padding: 24px;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid var(--ion-color-light-shade);
      .calendar-image {
        margin: 0 16px 0 0 !important;
        img {
          margin: 0 !important;
        }
      }
      h2 {
        text-align: left;
        margin: 0 !important;
      }
      h6 {
        font-size: 16px !important;
        text-align: left;
        margin: 0 !important;
      }
    }
    &__date-picker {
      height: 580px;
      background: var(--ion-color-white);
      border-radius: 0 0 10px 10px;
      overflow: auto;
      .hk-desktop-appointment-proposals-dates {
        &-months {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          font-weight: bold;
          padding: 24px 40px;
          &-prev {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            min-width: 60px;
            font-size: 16px;
            color: var(--ion-color-grey);
            &:hover {
              cursor: pointer;
            }
            ion-icon {
              font-size: 20px;
              margin-right: 6px;
            }
          }
          &-next {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 60px;
            font-size: 16px;
            color: var(--ion-color-grey);
            &:hover {
              cursor: pointer;
            }
            ion-icon {
              font-size: 20px;
              margin-left: 6px;
            }
          }
          &-current {
            font-size: 20px;
          }
        }
        &-slide {
          display: flex;
          width: 100%;
          height: 100%;
          margin-bottom: 16px;
          ion-button {
            height: 160px;
          }
          .swiper-container {
            .swiper-slide {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              h2 {
                font-weight: bold;
                padding: 0 0.5rem;
                margin-top: 40px;
                margin-bottom: 24px;
              }
              p {
                padding: 0 20px;
                font-size: 16px;
                line-height: 1.5;
                color: var(--ion-color-medium, #60646b);
              }
            }
          }
          &-date {
            height: 160px;
            min-width: 92px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background-color: var(--ion-color-step-950);
            border-radius: 10px;
            margin: 4px;
            &:hover {
              cursor: pointer;
            }
            &.selected {
              color: white;
              background-color: var(--ion-color-primary);
            }
            &.disabled {
              color: var(--ion-color-step-750);
            }
            &-num {
              font-size: 30px;
              font-weight: bold;
            }
            &-day {
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .hk-desktop-appointment-proposals-dates-list {
    width: 35%;
    height: 684px;
    background: var(--ion-color-white);
    padding: 0 24px;
    border-radius: 10px;
    overflow: auto;

    .hk-desktop-appointment-proposals-list-header {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }
    .hk-list-item-description {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .hk-desktop-appointment-proposals-no-dates {
      background-color: var(--ion-background-color);
      text-align: center;
      padding: 40px;
      margin-top: 24px;
      border-radius: 10px;
      img {
        height: 60px;
      }
      h4 {
        font-size: 18px;
        font-weight: bold;
        color: var(--ion-color-grey);
      }
    }
  }
}

// Mobile Styles
.hk-appointment-proposals {
  width: 100%;
  &-dates {
    &-months {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      ion-icon {
        font-size: 18px;
      }
      &-prev {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 60px;
        font-size: 16px;
        color: var(--ion-color-grey);
      }
      &-next {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 60px;
        font-size: 16px;
        color: var(--ion-color-grey);
      }
      &-current {
        font-weight: bold;
      }
    }
    &-list {
      height: 94px;
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      &-date {
        height: 80px;
        flex: 0 0 57px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--ion-color-step-950);
        border-radius: 10px;
        margin: 4px;
        &.selected {
          color: white;
          background-color: var(--ion-color-primary);
        }
        &.disabled {
          color: var(--ion-color-step-750);
        }
        &-num {
          font-size: 25px;
          font-weight: bold;
        }
        &-day {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  &-no-dates {
    background-color: var(--ion-background-color);
    text-align: center;
    padding: 40px;
    margin-top: 24px;
    border-radius: 10px;
    img {
      height: 80px;
    }
    h4 {
      font-size: 18px;
      font-weight: bold;
      color: var(--ion-color-grey);
    }
  }

  &-list {
    &-header {
      font-weight: bold;
      font-size: 18px;
      margin-top: 24px;
    }
    &-none {
      background-color: var(--ion-background-color);
      text-align: center;
      padding: 16px;
      border-radius: 10px;
      img {
        height: 40px;
      }
      h5 {
        font-size: 16px;
        font-weight: bold;
        color: var(--ion-color-step-600);
        margin-bottom: 0;
      }
    }
    .hk-list-item {
      &-title,
      &-description {
        color: var(--ion-color-grey) !important;
      }
      &-description {
        margin-top: 8px;
      }
      &-end-icon {
        color: var(--ion-color-medium) !important;
      }
    }
  }

  .hk-body-spinner {
    height: 50%;
  }
}
