.hk-toast {
  &.primary {
    --button-color: var(--ion-color-primary);
    &::part(container) {
      border: 2px solid var(--ion-color-primary);
    }
  }

  &.danger {
    --button-color: var(--ion-color-danger);
    &::part(container) {
      border: 2px solid var(--ion-color-danger);
    }
  }

  &::part(container) {
    border-radius: 14px;
    background: #1b1c1f !important;
    color: #fff;
    backdrop-filter: none;
  }

  &::part(button) {
    padding-right: 0;
  }

  div {
    background: transparent !important;
  }

  &-icon {
    font-size: 16px !important;
    height: 16px;
    width: 16px;
  }
}
