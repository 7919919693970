@import 'src/theme/variables.scss';

// Desktop View Styles
.hk-desktop-appointment-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
  background-color: var(--ion-color-grey);
  padding: 24px;
  color: white;
  --min-height: 100px;
  height: 100px;
  &:hover {
    cursor: pointer;
    background-color: var(--hk-color-discovery);
    border: 2px solid var(--ion-color-primary);
    border-radius: 10px;
    transition: 50ms ease;
  }
  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33%;
    margin-right: 50px;
    .hk-desktop-season-icon {
      min-width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 50%;
      background-color: var(--ion-color-primary);
      margin-right: 24px;
    }
    &__name {
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      &--completed {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        .hk-complete-pill {
          font-family: 'Open Sans', sans-serif;
          font-weight: bold;
          font-size: 10px;
          width: 65px;
          border-radius: 3px;
          padding: 3px 8px;
          margin-top: 4px;
          border: 1px solid var(--hk-color-enterprise);
          background-color: tint(#00d2b7, 85);
        }
      }
    }
  }

  &-body {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    width: 33%;
    margin-right: 50px;
    ion-thumbnail {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      img {
        border-radius: 4px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33%;
    font-size: 16px;
    &__details {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-title {
        margin-bottom: 4px;
      }
    }
    ion-icon {
      font-size: 24px;
    }
  }

  &.finished {
    background-color: #fff;
    color: #000 !important;
  }

  &.finished &-footer {
    justify-content: space-between;
    align-items: center;
  }
}

// Mobile View Styles
.hk-appointment-card {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;

  &-header-wrapper {
    background-color: var(--ion-color-grey);
    padding: 16px;
  }

  &-header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      flex: 1 1 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .hk-season-icon {
      flex: 0 0 12px;
      width: 12px;
      height: 12px;
      padding: 4px;
      border-radius: 50%;
      background-color: var(--ion-color-primary);
      margin-right: 8px;
    }
  }

  &-body-wrapper {
    background-color: var(--ion-color-grey);
    padding: 0 16px 16px 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.finished .hk-appointment-card-body-wrapper {
    background-color: #fff;
    color: #000;
  }

  &-body {
    border-top: 1px solid #fff;
    width: 100%;
    display: flex;
    padding-top: 16px;

    ion-thumbnail {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      img {
        border-radius: 4px;
      }
    }

    p {
      margin-top: 0;
      font-size: 14px;
    }

    ion-button {
      font-weight: bold;
      width: 86px;
      height: 28px;
      border-radius: 14px;
    }
  }

  &-footer {
    display: flex;
    padding-top: 16px;
    font-size: 16px;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
    ion-icon {
      margin: 0 8px 0 0;
    }
  }

  &.finished &-footer {
    justify-content: space-between;
    align-items: center;
  }
}
