// Dashboard Styles
.hk-desktop-visit-card {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
  background-color: var(--ion-color-white);
  .hk-desktop-visit-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 164px;
    padding: 24px;
    &__header {
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: 24px;

      .hk-desktop-season-icon {
        min-width: 32px;
        height: 32px;
        padding: 8px;
        border-radius: 50%;
        background-color: var(--ion-color-primary);
        margin-right: 24px;
      }
      &-title {
        display: flex;
        flex-direction: column;
        &-name {
          font-size: 18px;
        }
        &-description {
          margin-top: 4px;
        }
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      width: 35%;
      margin-right: 24px;
      font-weight: bold;
      a {
        color: var(--ion-color-grey);
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          color: var(--ion-color-primary);
        }
      }
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      width: 15%;
      .hk-desktop-schedule-button {
        --background: var(--ion-color-primary);
        --border-radius: 20px !important;
        --box-shadow: none;
        font-weight: bold;
        color: #fff;
        width: 96px;
        height: 40px;
        text-transform: none;
        letter-spacing: inherit;
        &.disabled {
          --background: var(--ion-color-step-900);
          color: var(--ion-color-step-500);
        }
      }
    }
  }
  .hk-desktop-visit-card-footer {
    display: flex;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-primary);
  }
}

// Mobile Styles
.hk-visit-card {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;

  &-header-wrapper {
    background-color: var(--ion-color-grey);
    padding: 16px;
  }
  &.no-schedule {
    .hk-visit-card-header-wrapper {
      background-color: var(--ion-color-step-500);
    }
    .hk-visit-card-header .hk-season-icon {
      background-color: #fff;
      color: var(--ion-color-step-700) !important;
    }
  }

  .hk-visit-card-header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hk-season-icon {
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      padding: 4px;
      border-radius: 50%;
      color: white;
      background-color: var(--ion-color-primary);
      margin-right: 8px;
    }

    &-name {
      flex: 1 1 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .hk-visit-card-body-wrapper {
    padding: 0 16px 16px 16px;
    background-color: #fff;
    border-top: none;

    .hk-visit-card-body {
      flex-direction: column;
      border-top: 1px solid #fff;
      width: 100%;
      display: flex;
      padding-top: 16px;

      ion-thumbnail {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          border-radius: 4px;
        }
      }

      p {
        margin-top: 0;
        font-size: 14px;
      }

      ion-button {
        font-weight: bold;
        width: 86px;
        height: 28px;
        border-radius: 14px;
      }

      .hk-schedule-button {
        --background: var(--ion-color-primary);
        color: #fff;
        &.disabled {
          --background: var(--ion-color-step-900);
          color: var(--ion-color-step-500);
        }
      }
    }
  }

  .hk-visit-card-footer {
    display: flex;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-primary);

    ion-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
