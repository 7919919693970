.main-menu {
  --background: var(--ion-color-dark);
  ion-content {
    --background: var(--ion-color-dark);
  }
  ion-header .hk-illustration {
    max-width: 50%;
    margin: 85px auto 30px auto;
    pointer-events: none;
  }
  ion-list {
    background: none;
  }
  ion-item {
    --border-style: none;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;

    &.no-bold {
      font-weight: normal;
      font-style: italic;
    }
  }
  ion-icon {
    font-size: 16px;
    margin: 0 10px;
  }
  ion-footer {
    border-top: 1px solid #494e5f;
    min-height: 180px;
    padding: 20px;
  }
}
