// Desktop Styles
.hk-desktop-appointment-wizard {
  --background: var(--ion-background-color) !important;
  ion-slides {
    height: 100%;
  }
}
.hk-desktop-slide-notes {
  .hk-slide-notes-container {
    max-width: 400px;
    max-height: 436px;
  }
  .hk-illustration {
    max-width: 62px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  .native-textarea {
    font-size: 16px !important;
  }
}

.hk-desktop-slide-confirmation {
  .hk-slide-confirmation-container {
    max-width: 400px;
    max-height: 436px;
  }
  .hk-illustration {
    max-width: 62px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  .native-textarea {
    font-size: 16px !important;
  }
}

.hk-desktop-slide-toolbar {
  ion-button {
    --box-shadow: none;
    width: 400px;
    margin: auto;
    text-transform: none;
    &:hover {
      cursor: pointer;
    }
  }
}

// Mobile Styles
.hk-appointment-wizard {
  --background: #fff;
  &-toolbar {
    --background: #fff !important;
  }
  .swiper-slide {
    flex-direction: column;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    h6 {
      font-size: 14px;
      color: var(--ion-color-grey);
      margin: 24px auto;
      &.hk-appointment-wizard-current {
        margin-top: 0;
      }
    }

    .hk-body-spinner {
      margin: 50%;
    }
  }

  .hk-illustration {
    max-width: 42px;
    margin: 0 auto;
    img {
      margin: 50px 0 16px 0;
    }
  }

  .native-textarea {
    border-top: 1px solid var(--ion-color-medium) !important;
    text-align: left;
    font-size: 14px;
    margin-top: 48px;
  }

  .list-ios,
  .list-md {
    background: #fff !important;
    width: 100%;
  }
  .hk-list-item {
    --background: var(--ion-background-color) !important;
    border-radius: 10px;
    margin: 16px 0;
    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-height: 750px) {
  .hk-appointment-wizard .hk-list-item {
    margin: 16px 0;
  }
}
